import { ProductBrowser } from '@ecwid/gatsby-plugin-ecwid';
import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import { PromoPopup } from '../components/organisms/PromoPopup';

const ShopMerch = ({
  data: {
    page,
    wp: {
      bannerSettings: {
        bannerSettings: { visible: bannerVisible },
      },
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <section className={`${bannerVisible ? 'pt-64' : 'pt-48'} pb-24`}>
          <div className="container">
            <ProductBrowser storeId="14454590" />
          </div>
        </section>
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query ShopMerchPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
    }
    wp {
      bannerSettings {
        bannerSettings {
          visible
        }
      }
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default ShopMerch;
